body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  border-radius: 50%;
}

html,
body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  margin: 0;

  width: 100%;
  height: 100%;
  font: 16px/2 "Roboto Mono";
  background-color: #292e3a;
  color: white;
}

ul {
  list-style-type: square;
  padding: 0 1em;
}

li a {
  display: block;
  color: inherit;
  text-decoration: none;
  padding-left: 0.5em;
  border-radius: 0.15em;
}

li a:hover {
  background: #212530;
}

@media (max-width: 500px) {
  html,
  body {
    display: inherit !important;
    padding: 1em;
  }

  main {
    width: 100%;
  }
}

* {
  box-sizing: border-box;
}

.pretty-text {
  background: linear-gradient(to right, #11998e 0%, #38ef7d 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: rainbow 5s ease infinite;
          animation: rainbow 5s ease infinite;
}

@-webkit-keyframes rainbow {
  from {
    -webkit-filter: hue-rotate(0deg);
            filter: hue-rotate(0deg);
  }
  to {
    -webkit-filter: hue-rotate(-360deg);
            filter: hue-rotate(-360deg);
  }
}

@keyframes rainbow {
  from {
    -webkit-filter: hue-rotate(0deg);
            filter: hue-rotate(0deg);
  }
  to {
    -webkit-filter: hue-rotate(-360deg);
            filter: hue-rotate(-360deg);
  }
}

